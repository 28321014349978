<template>
  <div class="order-query">
    <el-tabs
      v-model="name"
      @tab-click="handleDirect"
    >
      <el-tab-pane
        v-for="item in menus"
        :key="item.path"
        :name="item.path"
        :label="item.name"
      >
      </el-tab-pane>
    </el-tabs>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>

</template>

<script>
  export default {
    name: '',
    components: {},
    mounted() {
      let menus = []
      let vue = this
      if (this.$btnCheck('order_query_info')) {

        menus.push({
          name: '设备订单',
          path: 'order.query.device'
        })
      }

      if (this.$btnCheck('order_query_info')) {
        menus.push({
          name: '商城订单',
          path: 'order.query.o2o'
        })
      }

      this.menus = menus

      if (!menus.some((item) => {
        let ok = item.path === vue.$route.name
        if (ok) {
          this.name = item.path
        }
        return ok
      })) {
        this.name = menus[0].path
        this.$router.push({name: menus[0].path})
      }


    },
    data() {
      return {
        name: '',
        menus: [],
      }
    },
    methods: {
      handleDirect(table) {
        this.name = table.name
        this.$router.push({name: table.name})
      }
    }

  }
</script>

<style scoped lang="scss">
  .order-query {
    padding: $middle-space $container-padding;
  }
</style>

